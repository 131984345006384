import React, { memo, useMemo, useCallback } from 'react';
import { centsToDecimal } from '@apollo/core';
import Label from '../../components/I18n/Label';

const BonusPopupItem = ({
  bonus, currency, onActivate, isActive,
}) => {
  const { name, financeDataList, timeToWager } = bonus;

  const { maxBonusAmount, minDeposit } = useMemo(() => _.find(
    financeDataList,
    ({ currency: financeDataCurrency }) => financeDataCurrency === currency,
  ) || {}, []);

  const activateBonusHandler = useCallback(() => {
    onActivate(bonus);
  }, [bonus, onActivate]);

  return (
    <div className='bonusBlock'>
      <div className='bonusBlock__logo' />
      <div className='bonusBlock__content'>
        <div className='bonusBlock__content__top'>
          <div className='description'>
            <span>{name}</span>
          </div>
          <div className='button'>
            <button
              type='button'
              className='bBase activateBonus'
              disabled={isActive}
              onClick={activateBonusHandler}
            >
              <Label message={isActive ? 'activated' : 'activate'} />
            </button>
          </div>
        </div>
        <div className='bonusBlock__content__bottom'>
          <div className='bonusGroup'>
            <span className='name'>
              <Label message='min' />
              :
            </span>
            <span className='amount'>{centsToDecimal(minDeposit, currency)}</span>
          </div>
          <div className='bonusGroup'>
            <span className='name'>
              <Label message='max' />
              :
            </span>
            <span className='amount'>{centsToDecimal(maxBonusAmount, currency)}</span>
          </div>
          <div className='bonusGroup'>
            <span className='name'>
              <Label message='wagering' />
              <span>:</span>
            </span>
            <span className='amount'>
              {Math.floor(timeToWager / (1000 * 60 * 60 * 24))}
              {' '}
              <Label message='days' />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BonusPopupItem);
