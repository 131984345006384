import React from 'react';
import _ from 'lodash';
import BonusPopupItem from './BonusPopupItem';

const BonusPopupList = ({
  bonuses,
  currency,
  onActivateBonus,
  isActive,
}) => _.map(bonuses, (bonus) => <BonusPopupItem bonus={bonus} currency={currency} onActivate={onActivateBonus} isActive={isActive} />);

export default BonusPopupList;
