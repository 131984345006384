import React from 'react';
import { useApplicationState } from '@apollo/core';
import useTranslate from '../I18n/Interpreter';

const SelectedOddsButtons = ({ clearSelection, addToBet, clearDisabled, addDisabled }) => {
  const t = useTranslate();
  const { layout } = useApplicationState();

  return (
    <div className='add_to_bet__btn'>
      <div className='clear_section'>
        <button
          type='button'
          className='btn clear'
          disabled={clearDisabled}
          onClick={clearSelection}
        >
          <span className='icon AkrIcon-trash' />
          {' '}
          <span>{t('Clear')}</span>
        </button>
      </div>
      <button type='button' className='btn add' onClick={(e) => addToBet(e)} disabled={addDisabled}>
        {layout.mobileDevice ? t('add') : t('add_to_betslip')}
      </button>
    </div>
  );
};
export default SelectedOddsButtons;
