import React, { useCallback } from 'react';
import { isEmpty } from 'lodash';

import { Bus, useGlobalBetslipState } from '@apollo/core';
import { BET_STATUSES } from '@apollo/core/src/constants';
import MyBetsItem from '../MyBets/MyBetsItem';

export default function BetReportsList() {
  const { reports } = useGlobalBetslipState();

  const handleRemoveReport = useCallback(
    (removingBetId) => {
      Bus.emit(Bus.events.bet.removeBetReport, { betId: removingBetId });
    },
    [reports],
  );

  const handleRemoveAllReports = useCallback(() => {
    reports.forEach((report) => {
      Bus.emit(Bus.events.bet.removeBetReport, { betId: report.betId });
    });
  }, [reports]);

  const noBetProcessing = reports.every((bet) => bet.betStatus !== BET_STATUSES.PENDING_APPROVAL);

  return React.useMemo(() => {
    if (isEmpty(reports)) {
      return null;
    }

    return (
      <div className='bets__bet-reports'>
        {reports
          .map((report) => (
            <MyBetsItem key={report.betId} bet={report} removeReport={handleRemoveReport} />
          ))
          .reverse()}

        {noBetProcessing && (
          <div className='clear-reports-wrapper'>
            <button
              type='button'
              className='buttonCancel clear-reports'
              onClick={handleRemoveAllReports}
            >
              <span>Clear</span>
            </button>
          </div>
        )}
      </div>
    );
  }, [reports]);
}

/*
<div class="itemList placed">

    <div class="itemList__header">
      <span class="icon AkIcon-check-icon"></span>
      <span>Bet has been done successfully</span>
    </div>

    <div class="itemList__item">
      <div class="event">
        <div class="event__name">
          <span class="score"> 1 - 2 </span>
          <span>Handicap </span>
        </div>
        <div class="event__team">
          FC Dynamo Kyiv FC Dynamo Kyiv
          FC Dynamo Kyiv

        </div>
        <div class="event__team">
          New York Red Bulls
        </div>
      </div>
      <div class="info">
        Asian Corners Asian Corners
        Asian Corners

      </div>
      <div class="odd "> <!-------- add class up or down ----->
        <div class="odd__default">
          <span>2.6 </span>
        </div>
      </div>
    </div>

    <div class="itemList__controls">
      <button type="button" class="buttonCancel">
        <span>Clear all bets</span>
      </button>
      <button type="button" class="bBasePrimary">
        <span>OK ( 8 )</span>
      </button>
    </div>

  </div>
  */
