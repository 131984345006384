import React, { useEffect, useCallback } from 'react';
import _ from 'lodash';
import { NotificationManager } from '@apollo/core';
import useBonuses from '../../../features/ClientProfile/Bonuses/BonusesTable/useBonuses';
import Label from '../../components/I18n/Label';
import BonusPopupList from './BonusPopupList';

const RegistrationBonusPopup = (props) => {
  const [id, onClose = _.noop] = _.at(props, ['id', 'onClose']);
  const {
    activeBonuses, bonuses, activateBonus, isLoading, wallet,
  } = useBonuses();
  const { currency } = wallet;

  const closePopupHandler = useCallback(() => {
    NotificationManager.removeNotification(id);
    onClose();
  }, [id]);

  const activateBonusHandler = useCallback(async (bonus) => {
    await activateBonus(bonus);
  }, [activateBonus]);

  const formattedActiveBonuses = React.useMemo(() => _.map(activeBonuses, 'bonus'), [activeBonuses]);

  const areBonusesEmpty = bonuses.length === 0 && activeBonuses.length === 0;

  useEffect(() => {
    if (!isLoading && bonuses.length === 0 && activeBonuses.length === 0) {
      closePopupHandler();
    }
  }, [isLoading]);

  if (areBonusesEmpty) {
    return null;
  }

  // TODO: Add banner in container below when functionality be ready
  // const renderRegistrationBonusBanner = (
  //   <div className='logoBg'>
  //
  //   </div>
  // );

  return (
    <div className='popup'>
      <div className='popup__bonusRegistration'>
        <div className='block bonus'>
          <div className='block__title'>
            <Label message='registration_bonus_popup_header' />
          </div>
          <div className='block__content'>
            <div className='bonus'>
              <BonusPopupList
                currency={currency}
                bonuses={formattedActiveBonuses}
                isActive
              />
              <BonusPopupList
                onActivateBonus={activateBonusHandler}
                currency={currency}
                bonuses={bonuses}
              />
            </div>
            <button
              type='button'
              className='bBase bonus'
              onClick={closePopupHandler}
            >
              <Label message='continue' />
            </button>
          </div>
        </div>
        {/* {renderRegistrationBonusBanner} */}
      </div>
    </div>
  );
};

export default RegistrationBonusPopup;
