import React, { useEffect, useCallback, useReducer } from 'react';
import { BonusApi, NotificationManager, useApplicationState, useSettings } from '@apollo/core';
import { at, chain } from 'lodash';
import { bonusStatuses } from '../../../../core/constants';
import ToastManager from '../../../../core/ToastManager';
import Label from '../../../../shared/components/I18n/Label';

const SET_STATE = 'SET_STATE';
const MAX_NUMBER_OF_ACTIVE_BONUSES = 'MAX_NUMBER_OF_ACTIVE_BONUSES';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_STATE: {
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return state;
    }
  }
};

const initialState = {
  bonuses: [],
  activeBonuses: [],
  isLoading: true,
};

const useBonuses = () => {
  const [{ bonuses, activeBonuses, isLoading }, dispatch] = useReducer(reducer, initialState);
  const applicationState = useApplicationState();
  const [currency, wallet] = at(applicationState, ['wallet.currency', 'wallet']);
  const { lang, country } = useSettings();

  const {
    AVAILABLE,
    IN_PROGRESS,
    ACTIVATED,
    PENDING,
    COMPLETED,
    SPEND,
    EXPIRED,
    CANCELED,
    DISABLED,
    ACTIVATION_EXPIRED,
  } = bonusStatuses;
  const statusOrder = {
    [AVAILABLE]: 1,
    [IN_PROGRESS]: 2,
    [ACTIVATED]: 3,
    [PENDING]: 4,
    [COMPLETED]: 5,
    [SPEND]: 6,
    [EXPIRED]: 7,
    [CANCELED]: 8,
    [DISABLED]: 9,
    [ACTIVATION_EXPIRED]: 100,
  };

  const fetchBonuses = useCallback(async () => {
    dispatch({ type: SET_STATE, payload: { isLoading: true } });
    try {
      const { bonuses: newBonuses } = await BonusApi.getAvailableBonuses({
        currency,
        country: 'test',
        language: lang.toUpperCase(),
      });

      const { bonuses: newActiveBonuses } = await BonusApi.getActiveBonuses(lang.toUpperCase());

      dispatch({
        type: SET_STATE,
        payload: {
          bonuses: newBonuses,
          activeBonuses: chain(newActiveBonuses)
            .filter(({ status }) => status !== ACTIVATION_EXPIRED)
            .sort(
              ({ status: statusA }, { status: statusB }) => statusOrder[statusA] - statusOrder[statusB],
            )
            .value(),
          isLoading: false,
        },
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: SET_STATE, payload: { isLoading: false } });
    } finally {
      dispatch({ type: SET_STATE, payload: { isLoading: false } });
    }
  }, [currency, lang, country]);

  useEffect(() => {
    if (currency) {
      fetchBonuses();
    }
  }, [currency, fetchBonuses]);

  const activateBonus = useCallback(
    async (bonus) => {
      const { id, name } = bonus;

      try {
        await BonusApi.activateBonus(id);
        ToastManager.success(<Label message='bonus_activated' params={{ bonus: name }} />);
        fetchBonuses();
      } catch (err) {
        if (err.code === MAX_NUMBER_OF_ACTIVE_BONUSES) {
          NotificationManager.display({
            type: 'popup',
            status: 'popup',
            title: 'info',
            message: err.message,
          });
        } else {
          NotificationManager.notify({
            type: 'notification',
            status: 'error',
            title: 'error',
            message: err.message,
          });
        }
        throw err;
      }
    },
    [fetchBonuses],
  );

  const deactivateBonus = useCallback(
    async (bonus) => {
      const { id } = bonus;
      await BonusApi.cancelBonus(id);
      fetchBonuses();
    },
    [fetchBonuses],
  );

  return {
    bonuses,
    activeBonuses,
    isLoading,
    activateBonus,
    deactivateBonus,
    wallet,
  };
};

export default useBonuses;
