import React, { useCallback, useMemo } from 'react';
import {
  BetManager,
  Bus,
  centsToDecimal,
  extractBetsFromBetDtoOutcomes,
  getBetItemsForMulti,
  getBonusAssignedToBet,
  getIntUID,
  SiteConfigManager,
  useApplicationState,
  useBetslipState,
  useBetslipStateUpdate,
  useGlobalBetslipState,
  useSettings,
} from '@apollo/core';
import { isEmpty } from 'lodash';
import { BET_STATUSES, BET_TYPE, BONUS_TYPE, REJECTION_REASON } from '@apollo/core/src/constants';
import { clientProfileRoutes, COMPONENT_TYPES, paymentRoutes } from '@apollo/routing';
import { NavLink } from 'react-router-dom';
import BetTypeSelector from '../BetTypeSelector';
import PlaceBetButton from '../PlaceBetButton';
import SignInButton from '../BetslipSignInButton';
import Label from '../../../shared/components/I18n/Label';
import validateSelectedBet from '../validator';
import { SPECIAL_MARKETS } from '../../../core/constants';
import TotalStakeSection from '../BetslipDefaultView/TotalStakeSection';
import PossibleWinSection from '../BetslipDefaultView/PossibleWinSection';
import KeyPad from './KeyPad';
import { getCurrencySymbol } from '../../../core/utils';

const { payment } = clientProfileRoutes;
const { deposit } = paymentRoutes;
const { CLIENT_PROFILE } = COMPONENT_TYPES;

const PlaceBetControls = () => {
  const {
    betItems,
    betTypes,
    loading,
    confirming,
    amount,
    bonuses,
    settings,
    selectedBetType,
    isPossibleMulti,
    bonusAssignments,
  } = useBetslipState();

  const { reports } = useGlobalBetslipState();

  const { orderBet: isOrderBetSettingsAllowed } = settings;

  const clientProfilePath = SiteConfigManager.getComponentPath(CLIENT_PROFILE);

  const {
    authenticated,
    wallet: { detailedBalance },
    account: { currency },
    layout,
    betsCount,
  } = useApplicationState();

  const currencySymbol = getCurrencySymbol(currency);

  const hasPendingBets = useMemo(() => !!betsCount[BET_STATUSES.PENDING_APPROVAL], [betsCount]);

  const getBonusAssignedToBetByRequestId = useCallback(
    (requestId) => getBonusAssignedToBet({ requestId, bonusAssignments, bonuses }),
    [bonusAssignments, bonuses],
  );

  /*   const isFreebetSelected = useMemo(
    () => (bonusAssignments || []).some(({ bonusId }) => Object.values(bonuses || {})
      .some((bonuses) => bonuses
        .find((bonus) => bonus.id === bonusId && bonus.bonusType === BONUS_TYPE.FREEBET))),
    [bonuses, bonusAssignments],
  ); */

  const [orderBet, setOrderBet] = React.useState(false);

  const betslipStateUpdate = useBetslipStateUpdate();

  const { quickBet } = useSettings();

  const removeAllBetItems = useCallback(() => BetManager.clearBets(), []);
  const clearReports = useCallback(() => BetManager.clearReports(), []);

  // Selected bet type or single bet item
  const selectedBet = useMemo(
    () => betTypes.find((betType) => betType.betType === selectedBetType) || betTypes[0] || {},
    [betTypes, selectedBetType],
  );

  const singleBets = useMemo(
    () => betTypes.find((betType) => betType.betType === BET_TYPE.SINGLE) || betTypes[0] || {},
    [betTypes],
  );

  const multiBets = useMemo(
    () => betTypes.find((betType) => betType.betType === BET_TYPE.MULTIPLE),
    [betTypes],
  );

  const allBetsActive = React.useMemo(
    () => betItems.every((bet) => bet.active && !bet.suspended),
    [betItems],
  );

  const oddsReducedBet = React.useMemo(
    () => reports.find((report) => report?.rejectionReason === REJECTION_REASON.ODDS_REDUCED),
    [reports],
  );

  // const isDisabled = loading || !isActive;

  const isOrderBetAllowed = React.useMemo(
    () => authenticated && isOrderBetSettingsAllowed && !isEmpty(betItems),
    [authenticated, betItems, isOrderBetSettingsAllowed],
  );

  React.useEffect(() => {
    if (!isOrderBetAllowed) {
      setOrderBet(false);
    }
  }, [isOrderBetAllowed]);

  const totalBetAmount = React.useMemo(() => {
    let betAmount = amount;
    // if (betItems.length === betItems.filter((item) => item.amount).length) {
    // no empty bet
    betItems.forEach((bet) => {
      betAmount += parseInt(bet.amount) || 0;
    });
    // }

    return betAmount;
  }, [betItems, amount]);

  const isLowBalance = React.useMemo(() => {
    const balance = detailedBalance?.CASH + detailedBalance?.BONUS ?? 0;
    let totalAmount = 0;

    if (multiBets && amount) {
      const assignedBonus = getBonusAssignedToBetByRequestId(multiBets.requestId);
      if (assignedBonus?.bonusType !== BONUS_TYPE.FREEBET) {
        // do not calculate free bet bonuses
        totalAmount += amount;
      }
    }

    betItems.forEach((bet) => {
      const assignedBonus = getBonusAssignedToBetByRequestId(bet.requestId);
      if (assignedBonus?.bonusType !== BONUS_TYPE.FREEBET) {
        // do not calculate free bet bonuses
        totalAmount += parseInt(bet.amount) || 0;
      }
    });

    return totalAmount > balance;
  }, [detailedBalance, amount, betItems, getBonusAssignedToBetByRequestId, multiBets]);

  const totalStakeDecimal = React.useMemo(() => centsToDecimal(totalBetAmount), [totalBetAmount]);

  const isOddsBoostOrPromoOdds = (bonus) => [BONUS_TYPE.ODDS_BOOST, BONUS_TYPE.PROMO_ODDS].includes(bonus?.bonusType);

  const possibleWin = React.useMemo(() => {
    if (!isPossibleMulti && betItems.length > 1) {
      // the possible win has sence if we have non conflicts bets (from different events)
      return null;
    }

    let possibleWin = 0;
    betItems.forEach((betItem) => {
      if (betItem.amount && betItem.odds) {
        const assignedBonus = getBonusAssignedToBetByRequestId(betItem.requestId);

        if (isOddsBoostOrPromoOdds(assignedBonus) && assignedBonus?.props?.boostedBetOdds) {
          possibleWin += betItem.amount * assignedBonus.props.boostedBetOdds || 0;
        } else {
          possibleWin += betItem.amount * betItem.odds || 0;

          if (assignedBonus?.bonusType === BONUS_TYPE.FREEBET) {
            possibleWin -= betItem.amount;
          }
        }
      }
    });
    if (multiBets && amount) {
      const assignedBonus = getBonusAssignedToBetByRequestId(multiBets.requestId);

      if (isOddsBoostOrPromoOdds(assignedBonus) && assignedBonus?.props?.boostedBetOdds) {
        possibleWin += amount * assignedBonus.props.boostedBetOdds || 0;
      } else {
        possibleWin += amount * multiBets.odds || 0;
        if (assignedBonus?.bonusType === BONUS_TYPE.FREEBET) {
          possibleWin -= amount;
        }
      }
    }
    return possibleWin;
  }, [isPossibleMulti, betItems, multiBets, amount, getBonusAssignedToBetByRequestId]);

  const toggleConfirmBet = React.useCallback(() => {
    betslipStateUpdate({
      confirming: !confirming,
    });
  }, [betslipStateUpdate, confirming]);

  const handleConfirmBet = React.useCallback(() => {
    // Canceling validation
    validateSelectedBet.cancel();

    const bets = [];
    singleBets.outcomes?.forEach((outcome) => {
      const bet = BetManager.betProcessor.processBet(
        { ...singleBets, outcomes: [outcome] },
        betItems,
      );

      const betItem = betItems.find((betItem) => betItem.requestId === outcome.requestId);

      if (!betItem.amount) {
        return;
      }

      // if (!bet.outcomes[0]?.odds) { // fixme: single bet doesn't contain odds
      bet.outcomes[0].odds = betItem.odds;
      // }

      // Setting bet amount
      bet.betMoney.betAmount = betItem.amount;
      bet.requestId = outcome.requestId;

      // Adding selected bonus
      const assignedBonus = getBonusAssignedToBetByRequestId(betItem.requestId);

      if (assignedBonus) {
        bet.bonusInfoList = [...(bet.bonusInfoList || []), assignedBonus];
      }

      bets.push(bet);
    });

    if (multiBets && amount) {
      const bet = BetManager.betProcessor.processBet(multiBets, betItems);

      // Setting bet amount
      bet.betMoney.betAmount = amount;

      // Adding selected bonuses
      const assignedBonus = getBonusAssignedToBetByRequestId(multiBets.requestId);

      if (assignedBonus) {
        bet.bonusInfoList = [...(bet.bonusInfoList || []), assignedBonus];
      }

      if (assignedBonus?.bonusType === BONUS_TYPE.FREEBET) {
        bet.betMoney.betAmount = assignedBonus.freeBetAmount;
      }

      bets.push(bet);
    }

    betItems.forEach((bet) => {
      // Add runners to racing state cache
      if (bet.event?.runners) {
        Bus.emit(Bus.events.racing.addBetslipRunners, bet);
      }
    });

    // Placing bets
    // console.log(335, 'bets', bets);
    BetManager.placeBets(bets).catch((err) => console.log(err));
  }, [multiBets, singleBets, amount, betItems, getBonusAssignedToBetByRequestId]);

  const handleClearAllExpiredBets = React.useCallback(() => {
    betItems.forEach((bet) => {
      const { suspended, active, eventId, outcomeId, marketTypeId, marketId, requestId } = bet;
      if (suspended || !active) {
        BetManager.removeBet({
          eventId,
          outcomeId,
          marketTypeId,
          marketId,
          requestId,
        });
      }
    });
  }, [betItems]);

  const handleConfirmReducedBet = () => {
    if (!oddsReducedBet) {
      return;
    }

    BetManager.focus('main');

    // Adding outcomes to betslip
    extractBetsFromBetDtoOutcomes(oddsReducedBet?.outcomes).forEach((bet) => {
      BetManager.addBet(bet);
    });

    // console.log(335, 'oddsReducedBet', oddsReducedBet);

    const bets = [
      // create a duplicate from rejected bet
      {
        acceptOddsChanges: oddsReducedBet.acceptOddsChanges,
        acceptParameterChanges: oddsReducedBet.acceptParameterChanges,
        betType: oddsReducedBet.betType,
        locale: oddsReducedBet.locale,
        betMoney: {
          betAmount: oddsReducedBet.betMoney.betAmount,
          currency: oddsReducedBet.betMoney.currency,
        },
        requestId: getIntUID(),
        outcomes: oddsReducedBet.outcomes.map((outcome) => ({
          outcomeId: outcome.outcomeId,
          odds:
            oddsReducedBet.updatedOdds.find(
              (o) => `${o.outcomeId}` === outcome.outcomeId, // updated odds
            )?.odds || outcome.odds,
          sportService: oddsReducedBet.betSportService,
        })),
      },
    ];

    // Placing bets
    BetManager.placeBets(bets).catch((err) => console.log(err));
  };

  const isTbd = React.useMemo(
    () => betItems.some((item) => SPECIAL_MARKETS[item.marketTypeId] && item.amount)
      || (isPossibleMulti
        && amount
        && getBetItemsForMulti(betItems).some((item) => SPECIAL_MARKETS[item.marketTypeId])),
    [betItems, isPossibleMulti, amount],
  );

  return (
    <>
      {!isEmpty(betItems) && (
        <div className='place-bet-controls bets__betslip'>
          {!orderBet && (
            <>
              <div className='controls__block'>
                <BetTypeSelector
                  className='place-bet-controls__type-selector'
                  betTypes={betTypes}
                  selectedBet={selectedBet}
                  confirming={confirming}
                />

                <TotalStakeSection currencySymbol={currencySymbol} totalStake={totalStakeDecimal} />
              </div>
              <div className='controls__block'>
                <PossibleWinSection
                  possibleWin={possibleWin}
                  currencySymbol={currencySymbol}
                  isTbd={isTbd}
                />
              </div>
              {layout.keyPadMoneyInput && layout.mobileDevice ? (
                <KeyPad key={layout.keyPadMoneyInput} inputParentSelector='.main__sidebar--right' />
              ) : null}

              {/* {!isSingleBets
                && (selectedFreebet ? (
                  <FreebetMoneyBlock
                    selectedBet={selectedBet}
                    currency={currency}
                    freebet={selectedFreebet}
                  />
                ) : !confirming ? (
                  <MoneyBlock
                    selectedBet={selectedBet}
                    isActive={isActive}
                    betAmount={amount}
                    currency={currency}
                    bonuses={bonuses[selectedBet.requestId]}
                    authenticated={authenticated}
                    setBetAmount={handleBetAmountChange}
                  />
                ) : (
                  <div className='controls__block'>
                    <PossibleWinSection
                      possibleWin={
                        selectedBet.odds
                        && betAmount
                        && selectedBet.odds * betAmount
                      }
                      currencySymbol={currencySymbol}
                    />
                  </div>
                ))} */}
            </>
          )}
          <div className={`place-bet-controls__buttons ${isLowBalance ? 'low-balance' : ''}`}>
            {!orderBet
              && (isLowBalance && authenticated ? (
                <div className='low-balance__block'>
                  <h4>
                    <Label message='Low balance title' />
                  </h4>
                  <Label message='Low balance text' />
                  <NavLink
                    to={`${clientProfilePath}/${payment}/${deposit}`}
                    onClick={() => {
                      Bus.send({
                        event: Bus.events.layout.setSideBarRight,
                        data: false,
                      });
                    }}
                    className='btn buttonBet'
                  >
                    <Label message='make deposit' />
                  </NavLink>
                </div>
              ) : (
                <div className='place-bet-controls__place-bet'>
                  {authenticated ? (
                    <PlaceBetButton
                      disabled={!allBetsActive}
                      pendingBets={hasPendingBets}
                      onSubmit={confirming || quickBet ? handleConfirmBet : toggleConfirmBet}
                      betAmount={totalBetAmount}
                      handleClearAllExpiredBets={handleClearAllExpiredBets}
                    />
                  ) : (
                    <SignInButton />
                  )}
                </div>
              ))}
            <div className='clear_bets'>
              {confirming ? (
                <button
                  type='button'
                  className='btn buttonCancel cancel-confirm'
                  disabled={loading || hasPendingBets}
                  onClick={toggleConfirmBet}
                >
                  <Label message='Edit Bet' />
                </button>
              ) : (
                <button
                  type='button'
                  className='btn buttonCancel remove-all-bets'
                  disabled={loading || hasPendingBets}
                  onClick={removeAllBetItems}
                >
                  <Label message='clear_all_bets' />
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {oddsReducedBet && (
        <div className='place-bet-controls bets__betslip'>
          <div className='place-bet-controls__place-bet'>
            <button
              type='button'
              className='btn buttonBet buttonBet__acceptChanges'
              onClick={() => handleConfirmReducedBet()}
            >
              <Label message='betslip_accept_odds_changes' />
            </button>
          </div>
          {isEmpty(betItems) && (
            <div className='clear_bets'>
              <button
                type='button'
                className='btn buttonCancel'
                disabled={loading || hasPendingBets}
                onClick={clearReports}
              >
                <Label message='decline' />
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PlaceBetControls;
