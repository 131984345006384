import React, { useCallback, useMemo } from 'react';
import { isEmpty, isFinite, round } from 'lodash';
import cx from 'classnames';
import {
  actions,
  BetManager,
  BETSLIP_VIOLATION,
  centsToDecimal,
  constants,
  isExoticMarket,
  isMultilegsMarket,
  SportManager,
  useApplicationState,
  useBetAmountChangeHandler,
  useBetItem,
  useObservableOddsContext,
} from '@apollo/core';
import {
  BET_EVENT_TYPE,
  BONUS_TYPE,
  RaceLogicMarkets,
  RaceMarketsTabs,
} from '@apollo/core/src/constants';
import { useRacingState } from '@apollo/core/src/state/racing/racing';
import Label from '../../../shared/components/I18n/Label';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import PossibleWinSection from '../BetslipDefaultView/PossibleWinSection';
import TotalStakeSection from '../BetslipDefaultView/TotalStakeSection';
import { SPECIAL_MARKETS, sportProviders } from '../../../core/constants';
import MarketsDropdown from './MarketsDropdown';
import BetItemViewSport from './BetItemViewSport';
import BetItemViewRacing from './BetItemViewRacing';
import BetItemViewSrm from './BetItemViewSrm';
import BetItemViewSgm from './BetItemViewSgm';
import BetSubItemViewSrm from './BetSubItemViewSrm';
import BetSubItemViewSgm from './BetSubItemViewSgm';
import PercentageInput from '../../../shared/components/Form/PercentageInput/PercentageInput';
import MoneyInputBlock from './MoneyInputBlock';
import BetBonusButtons from './BetBonusButtons';
import { getCurrencySymbol } from '../../../core/utils';
import { RacingIcon } from '../../../shared/components/Racing/RacingIcon';
import RaceCountdown from '../../../shared/components/Racing/RaceCountdown/RaceCountdown';

const BetItemViewWrapper = (props) => {
  const { children, className, onRemove, readonly, errors, outcomeId, isClosed, hasNote } = props;

  const betItemError = React.useMemo(
    () => (isEmpty(errors)
      ? null
      : errors.find((error) => error.violationInfo?.outcomeId === `${outcomeId}`)),
    [errors, outcomeId],
  );

  return (
    <>
      {betItemError && (
        <div
          className={`betError ${
            betItemError.code === BETSLIP_VIOLATION.OUTCOME_ODDS_CHANGED ? 'warning' : ''
          }`}
        >
          {betItemError.code === BETSLIP_VIOLATION.OUTCOME_ODDS_CHANGED && (
            <i className='AkrIcon-warning' />
          )}
          <Label
            className='error'
            params={betItemError.violationInfo}
            message={`betslip_error_${betItemError.code}`}
          />
        </div>
      )}
      <div className={className}>
        {readonly ? null : (
          <div className='close' onClick={onRemove}>
            &times;
          </div>
        )}

        {children}
        {isClosed ? (
          <div className='itemList__item__alert'>
            <Label message='closed_event' />
          </div>
        ) : null}
        {hasNote ? (
          <div className='itemList__item__note'>
            <div className='itemList__item__note--header'>
              <Label message='note header' />
            </div>
            <div className='itemList__item__note--body'>
              <Label message='note body sportcast' />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

const Loading = () => (
  <div className='loading'>
    <Label message='loading' />
  </div>
);

const Error = (props) => {
  const { error } = props;
  return (
    <div className='error'>
      <span>{error.error}</span>
      <span>{error.message}</span>
    </div>
  );
};

const MarketTypeBlock = React.memo(
  ({ betEventType, marketType, marketTab, requestId, event, outcome, disabled }) => {
    const t = useTranslate();

    if (marketType === RaceLogicMarkets.SAME_RACE_MULTI) {
      return <div className='b-col markets__block' />;
    }

    if (isExoticMarket(marketType) || isMultilegsMarket(marketType)) {
      return null;
    }

    if (betEventType !== BET_EVENT_TYPE.RACING) {
      return (
        <div className='b-col markets__block'>
          <span className='markets-name'>{t('win')}</span>
        </div>
      );
    }

    if (marketTab === RaceMarketsTabs.EXTRA || marketType === RaceLogicMarkets.COMBINED) {
      return (
        <div className='b-col markets__block'>
          <span className='markets-name'>{t('win fixed')}</span>
        </div>
      );
    }

    return (
      <div className='b-col markets__block'>
        <MarketsDropdown
          requestId={requestId}
          selectedMarket={marketType}
          event={event}
          outcomeSelection={outcome.selection}
          disabled={disabled}
        />
      </div>
    );
  },
);

const BetListItem = ({
  invalid,
  readonly,
  confirming,
  alreadyPlaced,
  focused,
  bonuses,
  assignedBonus,
  amount,
}) => {
  const isMounted = React.useRef(false);
  const { layout } = useApplicationState();
  const {
    betEventType,
    eventId,
    loading,
    outcomeId,
    marketTypeId,
    marketId,
    event,
    marketType,
    outcome,
    suspended,
    feedOffline,
    active,
    errors,
    error,
    prevOutcome,
    requestId,
    isNew,
    odds,
    maxBetAmount,
    oddsToDisplay,
    // Racing
    market,
    runner,
  } = useBetItem();

  const [racingState] = useRacingState();
  const t = useTranslate();

  const {
    account: { currency },
  } = useApplicationState();

  const { hasUnacceptedOdds, odds: observableOdds } = useObservableOddsContext();

  const currencySymbol = getCurrencySymbol(currency);

  const handleBetRemove = React.useCallback(() => {
    BetManager.removeBet({
      eventId,
      outcomeId,
      marketTypeId,
      marketId,
      requestId,
    });
  }, [eventId, outcomeId, marketTypeId, marketId, requestId]);

  const disabled = !active;

  const isSrm = marketType === RaceLogicMarkets.SAME_RACE_MULTI;

  // for unclear reason after update in state sgmLegs becomes an object
  // e.g. {0: 1234, 1: 3455}. Maybe this is related to how objects are merged in core-lib
  // here using Object.values to get array of ids consistently
  const sgmLegs = outcome.sgmLegs ? Object.values(outcome.sgmLegs) : null;

  const isSgm = sgmLegs && sgmLegs.length > 0;

  const getRunners = useCallback(
    (outcome) => {
      if (event && event.runners && event.runners.length) {
        return event.runners;
      }

      if (!racingState || !outcome || !outcome.outcomeInfo) {
        return [];
      }

      if (
        racingState.betslipRunnersByEventId
        && racingState.betslipRunnersByEventId[outcome.outcomeInfo.eventId]
        && racingState.betslipRunnersByEventId[outcome.outcomeInfo.eventId].length
      ) {
        return racingState.betslipRunnersByEventId[outcome.outcomeInfo.eventId];
      }
      return outcome.outcomeInfo.runners || [];
    },
    [event, racingState?.betslipRunnersByEventId],
  );

  React.useEffect(() => {
    const justAddedHandler = window.setTimeout(() => {
      SportManager.dataBroadcaster.emit(`event:${eventId}`, {
        type: actions.BET_ITEM_STATE_UPDATE,
        payload: {
          isNew: false,
        },
      });
    }, 2000);

    return () => {
      window.clearTimeout(justAddedHandler);
    };
  }, []);

  // Self destruction
  // Triggered when bet item disabled without suspending
  React.useEffect(() => {
    if (disabled && !suspended && !loading) {
      const selfDestructionTimeoutId = window.setTimeout(() => handleBetRemove(), 3000);

      return () => window.clearTimeout(selfDestructionTimeoutId);
    }
  }, [disabled, suspended, loading, handleBetRemove]);

  React.useEffect(() => {
    isMounted.current = true;
  }, []);
  const isAmountFocused = React.useMemo(
    () => Boolean(focused && layout.sideBarRight && isMounted),
    [focused, layout.sideBarRight, isMounted],
  );

  const containerClassName = cx(
    'itemList__item',
    `marketType--${typeof marketType === 'string' ? marketType.toLowerCase() : 'unknown'}`,
    {
      new: isNew,
      suspended: suspended || feedOffline,
      disabled,
      readonly: confirming || readonly,
      error: error || !isEmpty(errors),
      'has-unaccepted-odds': hasUnacceptedOdds && observableOdds[outcomeId] !== odds,
      warning: !isEmpty(invalid),
    },
  );

  const View = React.useMemo(() => {
    if (loading) {
      return Loading;
    }

    if (error) {
      return Error;
    }

    switch (betEventType) {
      case BET_EVENT_TYPE.RACING:
        return isSrm ? BetItemViewSrm : BetItemViewRacing;

      case BET_EVENT_TYPE.SPORT_PREMATCH:
        return isSgm ? BetItemViewSgm : BetItemViewSport;
      default:
        return BetItemViewSport;
    }
  }, [betEventType, loading, error, isSrm, isSgm]);

  const betAmountUpdate = useBetAmountChangeHandler();

  const possibleWin = useMemo(() => {
    let possibleWin = isSrm
      ? outcome?.odds && amount && outcome.odds * amount
      : oddsToDisplay && amount && oddsToDisplay * amount;

    if (assignedBonus?.bonusType === BONUS_TYPE.FREEBET) {
      possibleWin -= amount;
    }

    return possibleWin;
  }, [isSrm, amount, oddsToDisplay, outcome, assignedBonus]);

  const handleBetAmountChange = React.useCallback(
    (amount, force = false) => {
      if (assignedBonus?.bonusType !== BONUS_TYPE.FREEBET || force) {
        betAmountUpdate(amount);
      }
    },
    [betAmountUpdate, assignedBonus],
  );

  const handleBetPercentageChange = React.useCallback(
    (amount) => {
      betAmountUpdate(amount * (outcome?.combos || 1));
    },
    [betAmountUpdate, outcome],
  );

  const isExotic = isExoticMarket(market.type);

  const isMulitlegs = isMultilegsMarket(market.type);

  if (!amount && confirming && !readonly) {
    return null;
  }

  if (alreadyPlaced && !readonly) {
    // if bet was placed before and not a part of multi
    return null;
  }

  return (
    <BetItemViewWrapper
      className={containerClassName}
      onRemove={handleBetRemove}
      readonly={confirming || readonly}
      errors={errors}
      outcomeId={outcomeId}
      isClosed={suspended}
      hasNote={marketType?.providerName === sportProviders.Sportcast}
    >
      <View
        betEventType={betEventType}
        event={event}
        outcome={outcome}
        market={market}
        runner={runner}
        runners={getRunners(outcome)}
        marketType={marketType}
        info={event.info}
        participants={event.participants}
        prevOutcome={prevOutcome}
        disabled={disabled}
        suspended={suspended}
        error={error}
        loading={loading}
        oddsToDisplay={oddsToDisplay}
        assignedBonus={assignedBonus}
      />

      {!loading
        && !error
        && !readonly
        && (confirming ? (
          <>
            <div className='controls__block'>
              {(isExotic || isMulitlegs) && isFinite(amount) ? (
                <div className='total-amount'>
                  <Label className='possible-win__title' message='exotic_flexi' />
                  <span className='combos'>
                    {`${round((amount / (outcome?.combos || 1)) * 100) / 100}%`}
                  </span>
                </div>
              ) : null}
              <TotalStakeSection
                currencySymbol={currencySymbol}
                totalStake={centsToDecimal(amount)}
                label='stake'
              />
            </div>
            <div className='controls__block'>
              {assignedBonus ? (
                <div className='bonus-type'>
                  <i
                    className={`icons icon--${assignedBonus.bonusType.toLowerCase()}`}
                    title={t(assignedBonus.bonusType)}
                  />
                  <Label message={assignedBonus.bonusType} className='bonus__description' />
                </div>
              ) : null}

              <PossibleWinSection
                possibleWin={possibleWin}
                currencySymbol={currencySymbol}
                isTbd={SPECIAL_MARKETS[market?.type]}
              />
            </div>
            {isExotic || isMulitlegs ? (
              <div className='controls__block'>
                <div>
                  <Label className='possible-win__title' message='combos' />
                  <span className='combos'>{outcome?.combos}</span>
                </div>
                <div>
                  <Label className='possible-win__title' message='stake per combo' />
                  <span className='combos'>
                    {currencySymbol}
                    {centsToDecimal(amount / (outcome?.combos || 1))}
                  </span>
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <>
            <div className='b-row controls__block'>
              <MarketTypeBlock
                betEventType={betEventType}
                requestId={requestId}
                marketType={marketType}
                marketTab={market.typeUiPosition}
                event={event}
                outcome={outcome}
                disabled={suspended || feedOffline || disabled || error}
              />

              {isExotic || isMulitlegs ? (
                <div className='b-col'>
                  <PercentageInput
                    value={amount}
                    combos={outcome?.combos || 1}
                    disabled={suspended || feedOffline || disabled || error}
                    onChange={handleBetPercentageChange}
                    placeholder='betslip_percentage'
                  />
                </div>
              ) : null}

              <div className='b-col'>
                <MoneyInputBlock
                  amount={amount}
                  bonuses={bonuses}
                  assignedBonus={assignedBonus}
                  disabled={suspended || feedOffline || disabled || error}
                  autofocus={!layout.mobileDevice}
                  isAmountFocused={isAmountFocused}
                  handleBetAmountChange={handleBetAmountChange}
                />
              </div>
            </div>

            {isExotic || isMulitlegs ? (
              <div className='max-bet__block with-combos'>
                <Label className='possible-win__title' message='combos' />
                <span className='combos'>{outcome?.combos}</span>
              </div>
            ) : null}

            <BetBonusButtons
              requestId={requestId}
              bonuses={bonuses}
              handleBetAmountChange={handleBetAmountChange}
            />

            {possibleWin ? (
              <div className='b-row controls__block possible-win__block'>
                <div className='b-col'>
                  <PossibleWinSection
                    possibleWin={possibleWin}
                    currencySymbol={currencySymbol}
                    isTbd={SPECIAL_MARKETS[market?.type]}
                  />
                </div>
              </div>
            ) : null}

            {maxBetAmount && (
              <div className='max-bet__block'>
                <Label message='max_bet' />
                <span
                  className='max-bet__amount'
                  onClick={() => handleBetAmountChange(maxBetAmount)}
                >
                  {currencySymbol}
                  {centsToDecimal(maxBetAmount)}
                </span>
              </div>
            )}
          </>
        ))}

      {isSrm && (
        <div className='event__name'>
          <span className='event-icon'>
            <RacingIcon id={event?.type} name={constants.RaceTypesMap?.[event?.type]?.typeName} />
          </span>
          <span>{`${event.venueName} R${event.number}`}</span>
          <RaceCountdown
            key={`${event?.raceId}-${event?.status}-${event?.result}`}
            date={new Date(event.startTime)}
            completeText={event.result || event.status}
          />
        </div>
      )}

      {isSgm && (
        <div className='event__name'>
          <div className='event-icon'>
            <span className={`icon sportIcon-${event.sportId}`} title={event.sportName} />
          </div>
          <div>{event.name}</div>
        </div>
      )}

      {isSrm ? (
        <BetSubItemViewSrm runners={getRunners(outcome)} outcome={outcome} raceType={event.type} />
      ) : null}

      {isSgm ? <BetSubItemViewSgm legs={sgmLegs} event={event} /> : null}
    </BetItemViewWrapper>
  );
};

export default BetListItem;
